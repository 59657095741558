export default {
  SETTINGS: {
    INDEX: {
      PATH: '/b/:businessSlug/settings/',
      NAME: 'settings'
    },
    BUSINESS: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/business',
        NAME: 'settings.business'
      },
      BUSINESS: {
        INDEX: {
          PATH: 'business',
          NAME: 'settings.business.index'
        },
        PROFILE: {
          PATH: 'profile',
          NAME: 'settings.business.profile'
        },
        SCHEDULERS: {
          PATH: 'schedulers',
          NAME: 'settings.business.schedulers'
        },
        COMMUNICATIONS: {
          PATH: 'communications',
          NAME: 'settings.business.communications'
        },
        CLASSROOMS: {
          PATH: 'classrooms',
          NAME: 'settings.business.classrooms'
        },
        ATTENDANCE: {
          PATH: 'attendance',
          NAME: 'settings.business.attendance'
        }
      },
      LOCATIONS: {
        INDEX: {
          PATH: 'locations',
          NAME: 'settings.business.locations'
        },
        LOCATION: {
          INDEX: {
            PATH: 'locations/:locationId',
            NAME: 'settings.business.locations.index'
          },
          PROFILE: {
            PATH: 'profile',
            NAME: 'settings.business.locations.profile'
          },
          ATTENDANCE: {
            PATH: 'attendance',
            NAME: 'settings.business.locations.attendance'
          },
          SCHEDULERS: {
            PATH: 'schedulers',
            NAME: 'settings.business.locations.schedulers'
          },
          COMMUNICATIONS: {
            PATH: 'communications',
            NAME: 'settings.business.locations.communications'
          },
          CLASSROOMS: {
            PATH: 'classrooms',
            NAME: 'settings.business.locations.classrooms'
          }
        }
      },
      DATA_FIELDS: {
        PATH: 'data-fields',
        NAME: 'settings.business.data-fields'
      },
      INTEGRATIONS: {
        INDEX: {
          PATH: 'integrations',
          NAME: 'settings.business.integrations.index'
        },
        GOOGLE: {
          PATH: 'integrations/google',
          NAME: 'settings.business.integrations.google'
        },
        FACEBOOK: {
          PATH: 'integrations/facebook',
          NAME: 'settings.business.integrations.facebook'
        },
        MICROSOFT: {
          PATH: 'integrations/microsoft',
          NAME: 'settings.business.integrations.microsoft'
        },
        ZOOM: {
          PATH: 'integrations/zoom',
          NAME: 'settings.business.integrations.zoom'
        },
        PROCARE_ONLINE: {
          PATH: 'integrations/procare-online/:integrationId?',
          NAME: 'settings.business.integrations.procare-online'
        },
        PROCARE: {
          PATH: 'integrations/procare/:integrationId?',
          NAME: 'settings.business.integrations.procare'
        },
        KANGAROOTIME: {
          PATH: 'integrations/kangarootime',
          NAME: 'settings.business.integrations.kangarootime'
        },
        FAMLY: {
          PATH: 'integrations/famly/:integrationId?',
          NAME: 'settings.business.integrations.famly'
        },
        CARECOM: {
          PATH: 'integrations/carecom/:integrationId?',
          NAME: 'settings.business.integrations.carecom'
        },
        WINNIE: {
          PATH: 'integrations/winnie/:integrationId?',
          NAME: 'settings.business.integrations.winnie'
        },
        LOOKERSTUDIO: {
          PATH: 'integrations/looker-studio/:integrationId?',
          NAME: 'settings.business.integrations.looker-studio'
        },
        APIV2: {
          PATH: 'integrations/apiv2/:integrationId?',
          NAME: 'settings.business.integrations.apiv2'
        }
      }
    },
    LMS: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/lms',
        NAME: 'settings.lms'
      },
      STATUSES: {
        PATH: 'statuses',
        NAME: 'settings.lms.statuses'
      },
      CHILD_STATUS: {
        PATH: 'child-statuses',
        NAME: 'settings.lms.child-status'
      },
      DATA_FIELDS: {
        INDEX: {
          PATH: 'data-fields',
          NAME: 'settings.lms.data-fields'
        },
        LEADS: {
          PATH: 'lead',
          NAME: 'settings.lms.data-fields.lead'
        },
        CONTACTS: {
          PATH: 'contacts',
          NAME: 'settings.lms.data-fields.contacts'
        },
        CHILDREN: {
          PATH: 'children',
          NAME: 'settings.lms.data-fields.children'
        },
        TRACKING: {
          PATH: 'tracking',
          NAME: 'settings.lms.data-fields.tracking'
        }
      }
    },
    EMS: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/ems',
        NAME: 'settings.ems'
      },
      STATUSES: {
        PATH: 'statuses',
        NAME: 'settings.ems.statuses'
      },
      DATA_FIELDS: {
        INDEX: {
          PATH: 'data-fields',
          NAME: 'settings.ems.data-fields'
        },
        CANDIDATES: {
          PATH: 'candidates',
          NAME: 'settings.ems.data-fields.candidates'
        },
        CONTACTS: {
          PATH: 'contacts',
          NAME: 'settings.ems.data-fields.contacts'
        },
        TRACKING: {
          PATH: 'tracking',
          NAME: 'settings.ems.data-fields.tracking'
        }
      }
    },
    MERCHANT: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/merchant',
        NAME: 'settings.merchant.index'
      },
      PROFILE: {
        PATH: 'profile',
        NAME: 'settings.merchant.profile'
      },
      PAYOUTS: {
        INDEX: {
          PATH: 'payouts',
          NAME: 'settings.merchant.payouts'
        },
        PAYOUT: {
          PATH: 'payouts/:payoutId',
          NAME: 'settings.merchant.payouts.show'
        }
      },
      TRANSACTIONS: {
        INDEX: {
          PATH: 'transactions',
          NAME: 'settings.merchant.transactions.index'
        },
        TRANSACTION: {
          PATH: 'transactions/:transactionId',
          NAME: 'settings.merchant.transactions.show'
        }
      },
      REPORTS: {
        PATH: 'reports',
        NAME: 'settings.merchant.reports'
      },
      COMPLIANCE_FORMS: {
        COMPLIANCE_FORM: {
          PATH: 'compliance-form/:complianceFormId',
          NAME: 'settings.merchant.compliance-form'
        }
      },
      FORM: {
        PATH: ':companyId/apply',
        NAME: 'settings.merchant.form'
      }
    },
    BRANDING: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/branding/',
        NAME: 'settings.branding'
      },
      EMAIL: {
        INDEX: {
          PATH: 'email',
          NAME: 'settings.branding.email.index'
        },
        EMAIL_THEMES: {
          PATH: 'themes',
          NAME: 'settings.branding.email.themes'
        },
        EMAIL_DESIGNS: {
          PATH: 'designs',
          NAME: 'settings.branding.email.designs'
        },
        CONFIG: {
          PATH: 'config',
          NAME: 'settings.branding.email.config'
        }
      },
      PHONE_NUMBERS: {
        INDEX: {
          PATH: 'phone-numbers',
          NAME: 'settings.branding.phone-numbers.index'
        },
        PHONE_NUMBERS: {
          PATH: 'phone',
          NAME: 'settings.branding.phone-numbers.phone'
        },
        COMPLIANCE: {
          PATH: 'compliance',
          NAME: 'settings.branding.phone-numbers.compliance'
        },
        FORM: {
          PATH: 'phone-numbers/:companyId/apply',
          NAME: 'settings.branding.phone-numbers.form'
        },
        SEARCH_NUMBERS: {
          PATH: 'phone-numbers/search-numbers',
          NAME: 'settings.branding.phone-numbers.search-numbers'
        }
      },
      DOMAINS: {
        INDEX: {
          PATH: 'domains',
          NAME: 'settings.branding.domains'
        },
        DOMAIN: {
          PATH: 'domains/:domainId',
          NAME: 'settings.branding.domains.domain'
        }
      }
    },
    ACCESS: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/access/',
        NAME: 'settings.access'
      },
      USERS: {
        INDEX: {
          PATH: 'users',
          NAME: 'settings.access.user'
        },
        ACTIVE: {
          PATH: 'active',
          NAME: 'settings.access.user.index'
        },
        INVITATIONS: {
          PATH: 'invitations',
          NAME: 'settings.access.user.invitations'
        },
        USER: {
          PATH: 'users/:userId',
          NAME: 'settings.access.user.show'
        }
      },
      ROLES: {
        PATH: 'roles',
        NAME: 'settings.access.roles'
      }
    },
    COMPANY: {
      INDEX: {
        PATH: '/b/:businessSlug/settings/company',
        NAME: 'settings.company'
      },
      LIST: {
        PATH: 'list',
        NAME: 'settings.company.list'
      }
    }
  }
}
