import { defineComponent, h } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { RouteList } from '../RouteList'

const NavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')

const LeadList = () => import('@/views/Ems/LeadList.vue')
const LeadShow = () => import('@/components/Lead/LeadShow.vue')

const StatisticsOverview = () => import('@/views/Statistics/StatisticsOverview.vue')
const StatisticsReports = () => import('@/views/Statistics/StatisticsReports.vue')
const StatisticsReportsLeadList = () => import('@/views/Statistics/StatisticsReportsLeadList.vue')
const StatisticsEmbeddedReport = () => import('@/views/Statistics/StatisticsEmbeddedReport.vue')

const EmailList = () => import('@/components/Conversation/EmailList.vue')
const SmsList = () => import('@/components/Conversation/SmsList.vue')
const CallList = () => import('@/components/Conversation/CallList.vue')
const MessengerList = () => import('@/components/Conversation/MessengerList.vue')

const WorkflowList = () => import('@/components/Marketing/WorkflowList.vue')
const WorkflowSingle = () => import('@/components/Marketing/WorkflowSingle.vue')
const CreateWorkflow = () => import('@/components/Marketing/CreateWorkflow.vue')

const CampaignList = () => import('@/components/Marketing/Campaign/CampaignList.vue')
const CampaignForm = () => import('@/components/Marketing/Campaign/CampaignForm.vue')
const CampaignShow = () => import('@/components/Marketing/Campaign/CampaignShow.vue')

const TemplatesList = {
  Email: () => import('@/components/Marketing/Template/Email/EmailTemplateList.vue'),
  Sms: () => import('@/components/Marketing/Template/Sms/SmsTemplateList.vue'),
  Task: () => import('@/components/Marketing/Template/Task/TaskTemplateList.vue'),
  Facebook: () => import('@/components/Marketing/Template/Facebook/FacebookTemplateList.vue')
}

const IntelliFormsPackagesView = () => import('@/views/Lms/IntelliForms/PackageListView.vue')
const IntelliFormsPackageView = () => import('@/views/Lms/IntelliForms/PackageView.vue')
const IntelliFormsPackageFormView = () => import('@/views/Lms/IntelliForms/PackageFormView.vue')

const IntelliFormsDocumentsView = () => import('@/views/Lms/IntelliForms/DocumentListView.vue')
const IntelliFormsDocumentFormView = () => import('@/views/Lms/IntelliForms/DocumentFormView.vue')

const ExportTools = {
  List: () => import('@/views/Lms/Tools/Export/ExportListView.vue'),
  Create: () => import('@/views/Lms/Tools/Export/ExportCreateView.vue')
}

const DuplicatesTools = {
  List: () => import('@/views/Lms/Tools/Duplicates/DuplicatesView.vue'),
  Custom: () => import('@/views/Lms/Tools/Duplicates/DuplicatesCustomMergeView.vue'),
  Merge: () => import('@/views/Lms/Tools/Duplicates/DuplicatesMergeView.vue')
}

const DivComponent = defineComponent({ render: () => h('div') })

export default <RouteRecordRaw[]>[
  {
    path: RouteList.EMS.EMS.PATH,
    name: RouteList.EMS.EMS.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Ems,
        title: 'EMS'
      }
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.EMS],
      permission: [AbilityEnum.EMS]
    },
    children: [
      {
        path: RouteList.EMS.LEADS.INDEX.PATH,
        name: RouteList.EMS.LEADS.INDEX.NAME,
        component: LeadList,
        meta: {
          title: 'Candidates',
          feature: [FeatureEnum.EMS_CANDIDATES, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_LEADS, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.LEADS.LEAD.PATH,
        name: RouteList.EMS.LEADS.LEAD.NAME,
        component: LeadShow,
        meta: {
          title: 'Candidate',
          feature: [FeatureEnum.EMS_CANDIDATES, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_LEAD_VIEW, AbilityEnum.EMS_LEADS, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.STATISTICS.INDEX.PATH,
        name: RouteList.EMS.STATISTICS.INDEX.NAME,
        meta: {
          feature: [FeatureEnum.EMS_STATISTICS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_STATISTIC, AbilityEnum.EMS]
        },
        redirect: {
          name: RouteList.EMS.STATISTICS.OVERVIEW.NAME
        }
      },
      {
        path: RouteList.EMS.STATISTICS.OVERVIEW.PATH,
        name: RouteList.EMS.STATISTICS.OVERVIEW.NAME,
        component: StatisticsOverview,
        meta: {
          title: 'Statistics Overview',
          feature: [
            FeatureEnum.EMS_STATISTICS_OVERVIEW,
            FeatureEnum.EMS_STATISTICS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_STATISTIC_QUICK_OVERVIEW,
            AbilityEnum.EMS_STATISTIC,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.STATISTICS.REPORTS.INDEX.PATH,
        name: RouteList.EMS.STATISTICS.REPORTS.INDEX.NAME,
        component: StatisticsReports,
        meta: {
          title: 'Statistics Reports',
          feature: [FeatureEnum.EMS_STATISTICS_REPORT, FeatureEnum.EMS_STATISTICS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_STATISTIC_REPORT, AbilityEnum.EMS_STATISTIC, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.STATISTICS.REPORTS.REPORT.PATH,
        name: RouteList.EMS.STATISTICS.REPORTS.REPORT.NAME,
        component: StatisticsReportsLeadList,
        meta: {
          title: 'Report',
          feature: [FeatureEnum.EMS_STATISTICS_REPORT, FeatureEnum.EMS_STATISTICS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_STATISTIC_REPORT, AbilityEnum.EMS_STATISTIC, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.STATISTICS.REPORTS.EMBEDDED_REPORT.PATH,
        name: RouteList.EMS.STATISTICS.REPORTS.EMBEDDED_REPORT.NAME,
        component: StatisticsEmbeddedReport,
        meta: {
          title: 'Embedded Report',
          feature: [FeatureEnum.EMS_STATISTICS_REPORT, FeatureEnum.EMS_STATISTICS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_STATISTIC_REPORT, AbilityEnum.EMS_STATISTIC, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.CONVERSATIONS.INDEX.PATH,
        name: RouteList.EMS.CONVERSATIONS.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [FeatureEnum.EMS_CONVERSATIONS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_CONVERSATION, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.CONVERSATIONS.CALLS.PATH,
        name: RouteList.EMS.CONVERSATIONS.CALLS.NAME,
        component: CallList,
        meta: {
          title: 'Conversations: Calls',
          feature: [
            FeatureEnum.EMS_CONVERSATIONS_CALL,
            FeatureEnum.EMS_CONVERSATIONS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_CONVERSATION_CALL,
            AbilityEnum.EMS_CONVERSATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.CONVERSATIONS.SMS.PATH,
        name: RouteList.EMS.CONVERSATIONS.SMS.NAME,
        component: SmsList,
        meta: {
          title: 'Conversations: SMS',
          feature: [
            FeatureEnum.EMS_CONVERSATIONS_SMS,
            FeatureEnum.EMS_CONVERSATIONS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_CONVERSATION_SMS,
            AbilityEnum.EMS_CONVERSATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.CONVERSATIONS.EMAILS.PATH,
        name: RouteList.EMS.CONVERSATIONS.EMAILS.NAME,
        component: EmailList,
        meta: {
          title: 'Conversations: Emails',
          feature: [
            FeatureEnum.EMS_CONVERSATIONS_EMAIL,
            FeatureEnum.EMS_CONVERSATIONS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_CONVERSATION_EMAIL,
            AbilityEnum.EMS_CONVERSATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.CONVERSATIONS.MESSENGER.PATH,
        name: RouteList.EMS.CONVERSATIONS.MESSENGER.NAME,
        component: MessengerList,
        meta: {
          title: 'Conversations: Messenger',
          feature: [
            FeatureEnum.EMS_CONVERSATIONS_MESSENGER,
            FeatureEnum.EMS_CONVERSATIONS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_CONVERSATION_MESSENGER,
            AbilityEnum.EMS_CONVERSATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.INDEX.PATH,
        name: RouteList.EMS.MARKETING.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [FeatureEnum.EMS_MARKETING_AUTOMATION, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_MARKETING_AUTOMATION, AbilityEnum.EMS]
        },
        redirect: {
          name: RouteList.EMS.MARKETING.WORKFLOWS.INDEX.NAME
        }
      },
      {
        path: RouteList.EMS.MARKETING.WORKFLOWS.INDEX.PATH,
        name: RouteList.EMS.MARKETING.WORKFLOWS.INDEX.NAME,
        component: WorkflowList,
        meta: {
          title: 'Workflows',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.WORKFLOWS.CREATE.PATH,
        name: RouteList.EMS.MARKETING.WORKFLOWS.CREATE.NAME,
        component: CreateWorkflow,
        meta: {
          title: 'New Workflow',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW_CREATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.WORKFLOWS.WORKFLOW.INDEX.PATH,
        name: RouteList.EMS.MARKETING.WORKFLOWS.WORKFLOW.INDEX.NAME,
        component: WorkflowSingle,
        meta: {
          title: 'Workflow',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW_VIEW,
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.WORKFLOWS.WORKFLOW.EDIT.PATH,
        name: RouteList.EMS.MARKETING.WORKFLOWS.WORKFLOW.EDIT.NAME,
        component: CreateWorkflow,
        meta: {
          title: 'Edit Workflow',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW_UPDATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW_VIEW,
            AbilityEnum.EMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.TEMPLATES.INDEX.PATH,
        name: RouteList.EMS.MARKETING.TEMPLATES.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        },
        redirect: {
          name: RouteList.EMS.MARKETING.TEMPLATES.EMAIL.INDEX.NAME
        }
      },
      {
        path: RouteList.EMS.MARKETING.TEMPLATES.EMAIL.INDEX.PATH,
        name: RouteList.EMS.MARKETING.TEMPLATES.EMAIL.INDEX.NAME,
        component: TemplatesList.Email,
        meta: {
          title: 'Templates: Email',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.TEMPLATES.SMS.INDEX.PATH,
        name: RouteList.EMS.MARKETING.TEMPLATES.SMS.INDEX.NAME,
        component: TemplatesList.Sms,
        meta: {
          title: 'Templates: SMS',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_SMS_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_SMS_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.TEMPLATES.TASK.INDEX.PATH,
        name: RouteList.EMS.MARKETING.TEMPLATES.TASK.INDEX.NAME,
        component: TemplatesList.Task,
        meta: {
          title: 'Templates: Task',
          feature: [
            FeatureEnum.SETTINGS_TASKS,
            FeatureEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.SETTING_TASKS_TEMPLATES,
            AbilityEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.TEMPLATES.FACEBOOK.INDEX.PATH,
        name: RouteList.EMS.MARKETING.TEMPLATES.FACEBOOK.INDEX.NAME,
        component: TemplatesList.Facebook,
        meta: {
          title: 'Templates: Facebook',
          feature: [
            // FeatureEnum.SETTINGS_FACEBOOK, // disabled until backend adds it? page doesnt load otherwise
            FeatureEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.INDEX.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        },
        redirect: {
          name: RouteList.EMS.MARKETING.CAMPAIGNS.SENT.NAME
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.SENT.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.SENT.NAME,
        component: CampaignList,
        meta: {
          title: 'Sent Campaigns',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.DRAFT.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.DRAFT.NAME,
        component: CampaignList,
        meta: {
          title: 'Draft Campaigns',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.SCHEDULED.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.SCHEDULED.NAME,
        component: CampaignList,
        meta: {
          title: 'Scheduled Campaigns',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.CREATE.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.CREATE.NAME,
        component: CampaignForm,
        meta: {
          title: 'New Campaign',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN_CREATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.SHOW.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.SHOW.NAME,
        component: CampaignShow,
        meta: {
          title: 'Campaign',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN_VIEW,
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.MARKETING.CAMPAIGNS.EDIT.PATH,
        name: RouteList.EMS.MARKETING.CAMPAIGNS.EDIT.NAME,
        component: CampaignForm,
        meta: {
          title: 'Draft Campaign',
          feature: [
            FeatureEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.EMS_MARKETING_AUTOMATION,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN_UPDATE,
            AbilityEnum.EMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.EMS_MARKETING_AUTOMATION,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.INDEX.PATH,
        name: RouteList.EMS.INTELLIFORMS.INDEX.NAME,
        meta: {
          feature: [FeatureEnum.EMS_INTELLIFORMS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_INTELLIFORM, AbilityEnum.EMS]
        },
        redirect: {
          name: RouteList.EMS.INTELLIFORMS.PACKAGES.INDEX.NAME
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.PACKAGES.INDEX.PATH,
        name: RouteList.EMS.INTELLIFORMS.PACKAGES.INDEX.NAME,
        component: IntelliFormsPackagesView,
        meta: {
          title: 'Packages',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_PACKAGE,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.PACKAGES.CREATE.PATH,
        name: RouteList.EMS.INTELLIFORMS.PACKAGES.CREATE.NAME,
        component: IntelliFormsPackageFormView,
        meta: {
          title: 'New Package',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_PACKAGE_CREATE,
            AbilityEnum.EMS_INTELLIFORM_PACKAGE,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.PACKAGES.PACKAGE.INDEX.PATH,
        name: RouteList.EMS.INTELLIFORMS.PACKAGES.PACKAGE.INDEX.NAME,
        component: IntelliFormsPackageView,
        meta: {
          title: 'Package',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_PACKAGE_VIEW,
            AbilityEnum.EMS_INTELLIFORM_PACKAGE,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.PACKAGES.PACKAGE.EDIT.PATH,
        name: RouteList.EMS.INTELLIFORMS.PACKAGES.PACKAGE.EDIT.NAME,
        component: IntelliFormsPackageFormView,
        meta: {
          title: 'Edit Package',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_PACKAGE_UPDATE,
            AbilityEnum.EMS_INTELLIFORM_PACKAGE_VIEW,
            AbilityEnum.EMS_INTELLIFORM_PACKAGE,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.DOCUMENTS.INDEX.PATH,
        name: RouteList.EMS.INTELLIFORMS.DOCUMENTS.INDEX.NAME,
        component: IntelliFormsDocumentsView,
        meta: {
          title: 'Documents',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.DOCUMENTS.CREATE.PATH,
        name: RouteList.EMS.INTELLIFORMS.DOCUMENTS.CREATE.NAME,
        component: IntelliFormsDocumentFormView,
        meta: {
          title: 'New Document',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_DOCUMENT_CREATE,
            AbilityEnum.EMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.INTELLIFORMS.DOCUMENTS.DOCUMENT.EDIT.PATH,
        name: RouteList.EMS.INTELLIFORMS.DOCUMENTS.DOCUMENT.EDIT.NAME,
        component: IntelliFormsDocumentFormView,
        meta: {
          title: 'Edit Document',
          feature: [
            FeatureEnum.EMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.EMS_INTELLIFORMS,
            FeatureEnum.EMS
          ],
          permission: [
            AbilityEnum.EMS_INTELLIFORM_DOCUMENT_UPDATE,
            AbilityEnum.EMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.EMS_INTELLIFORM,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.TOOLS.INDEX.PATH,
        name: RouteList.EMS.TOOLS.INDEX.NAME,
        redirect: {
          name: RouteList.EMS.TOOLS.EXPORT.INDEX.NAME
        },
        meta: {
          feature: [FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_TOOL, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.TOOLS.EXPORT.INDEX.PATH,
        name: RouteList.EMS.TOOLS.EXPORT.INDEX.NAME,
        component: ExportTools.List,
        meta: {
          feature: [FeatureEnum.EMS_TOOLS_EXPORT, FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_TOOL_EXPORT, AbilityEnum.EMS_TOOL, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.TOOLS.EXPORT.CREATE.PATH,
        name: RouteList.EMS.TOOLS.EXPORT.CREATE.NAME,
        component: ExportTools.Create,
        meta: {
          feature: [FeatureEnum.EMS_TOOLS_EXPORT, FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [AbilityEnum.EMS_TOOL_EXPORT, AbilityEnum.EMS_TOOL, AbilityEnum.EMS]
        }
      },
      {
        path: RouteList.EMS.TOOLS.DUPLICATES.INDEX.PATH,
        name: RouteList.EMS.TOOLS.DUPLICATES.INDEX.NAME,
        component: DuplicatesTools.List,
        meta: {
          feature: [FeatureEnum.EMS_TOOLS_DUPLICATE, FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [
            //AbilityEnum.EMS_TOOL_DUPLICATE,
            AbilityEnum.EMS_TOOL,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.TOOLS.DUPLICATES.CUSTOM.PATH,
        name: RouteList.EMS.TOOLS.DUPLICATES.CUSTOM.NAME,
        component: DuplicatesTools.Custom,
        meta: {
          feature: [FeatureEnum.EMS_TOOLS_DUPLICATE, FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [
            // AbilityEnum.EMS_TOOL_DUPLICATE,
            AbilityEnum.EMS_TOOL,
            AbilityEnum.EMS
          ]
        }
      },
      {
        path: RouteList.EMS.TOOLS.DUPLICATES.MERGE.PATH,
        name: RouteList.EMS.TOOLS.DUPLICATES.MERGE.NAME,
        component: DuplicatesTools.Merge,
        meta: {
          feature: [FeatureEnum.EMS_TOOLS_DUPLICATE, FeatureEnum.EMS_TOOLS, FeatureEnum.EMS],
          permission: [
            // AbilityEnum.EMS_TOOL_DUPLICATE,
            AbilityEnum.EMS_TOOL,
            AbilityEnum.EMS
          ]
        }
      }
    ]
  }
]
