export default {
  MARKETPLACE: {
    INDEX: {
      PATH: '/b/:businessSlug/marketplace',
      NAME: 'marketplace'
    },
    PUBLIC: {
      PARTNERS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/partners',
          NAME: 'marketplace.partners'
        },
        PARTNER: {
          PATH: '/b/:businessSlug/marketplace/partner/:id',
          NAME: 'marketplace.partners.partner'
        }
      },
      EMAILS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/public/emails',
          NAME: 'marketplace.public.emails'
        },
        EMAIL: {
          PATH: '/b/:businessSlug/marketplace/public/email/:partnerId/:id',
          NAME: 'marketplace.public.emails.email'
        }
      }
    },
    SUBSCRIPTIONS: {
      PARTNERS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/subscriptions/partners',
          NAME: 'marketplace.subscriptions.partners'
        },
        PARTNER: {
          PATH: '/b/:businessSlug/marketplace/subscriptions/partner/:id',
          NAME: 'marketplace.subscriptions.partners.partner'
        }
      },
      EMAILS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/subscriptions/emails',
          NAME: 'marketplace.subscriptions.emails'
        },
        EMAIL: {
          PATH: '/b/:businessSlug/marketplace/subscriptions/email/:partnerId/:id',
          NAME: 'marketplace.subscriptions.emails.email'
        }
      },
      SUBSCRIBE: {
        PATH: '/b/:businessSlug/marketplace/subscriptions/subscribe',
        NAME: 'marketplace.subscriptions.subscribe'
      }
    },
    SETTINGS: {
      PROFILE: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/settings',
          NAME: 'marketplace.settings.profile.index'
        },
        PROFILE: {
          PATH: '/b/:businessSlug/marketplace/settings/profile',
          NAME: 'marketplace.settings.profile'
        },
        CONFIGURATION: {
          PATH: '/b/:businessSlug/marketplace/settings/configuration',
          NAME: 'marketplace.settings.configuration'
        }
      },
      EMAILS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/settings/emails',
          NAME: 'marketplace.settings.emails'
        },
        EMAIL: {
          PATH: '/b/:businessSlug/marketplace/settings/email/:partnerId/:id',
          NAME: 'marketplace.settings.emails.email'
        },
        CREATE: {
          PATH: '/b/:businessSlug/marketplace/settings/email',
          NAME: 'marketplace.settings.emails.create'
        },
        EDIT: {
          PATH: '/b/:businessSlug/marketplace/settings/edit/:partnerId/:id',
          NAME: 'marketplace.settings.emails.edit'
        }
      },
      SUBSCRIBERS: {
        INDEX: {
          PATH: '/b/:businessSlug/marketplace/settings/subscribers',
          NAME: 'marketplace.settings.subscribers'
        }
      },
      FAQ: {
        PATH: '/b/:businessSlug/marketplace/settings/faq',
        NAME: 'marketplace.settings.faq'
      }
    }
  }
}
