import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import { computed, ComputedRef } from 'vue'
import { BusinessTypeEnum } from '@/shared'
import { RouteList } from '@/routes/RouteList'
import { notifyError } from '@/helpers/notification.helper'
import { RouteWhiteList } from '@/routes/RouteWhiteList'
import Auth from '@/routes/Auth'
import Dashboard from '@/routes/Dashboard'
import UserSettings from '@/routes/UserSettings'
import IntelliForms from '@/routes/Public/IntelliForms'
import Intercept from '@/routes/Public/Intercept'
import BackOffice from '@/routes/BackOffice'
import Public from '@/routes/Public'
import Marketplace from '@/routes/Marketplace'
import Website from '@/routes/Website'
import TaskRoutes from '@/routes/Tasks'
import Academy from '@/routes/Learning'
import UI from '@/routes/UI'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { featureExistsInFeatures } from '@/composables/useFeatures'
import BusinessDto from '@/dto/Business/BusinessDto'
import PermissionDto from '@/dto/Auth/PermissionDto'
import UserDto from '@/dto/Auth/UserDto'
import { permissionExistsInPermissions } from '@/composables/usePermissions'
import waitFor from '@/helpers/awaitValue.helper'
import { EMS_ROUTES, LMS_ROUTES, SETTING_ROUTES } from '@/enums/DynamicMenuEnum'
import { setDocumentTitle } from '@/helpers/window.helper'
import { LmsLeadRoutesEnum } from '@/enums/Lead/LeadEnum'
import { DummyBackOfficeSlug } from '@/shared'

const GuestNavBar = () => import('@/components/Layout/GuestNavBar.vue')
const ErrorLayout = () => import('@/components/Layout/ErrorLayout.vue')

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    requiresSubscription?: boolean
    feature?: FeatureEnum | FeatureEnum[]
    permission?: string | string[]
    admin?: boolean
    title?: string
  }
}

const router = createRouter({
  history: createWebHashHistory(
    '/' !== import.meta.env.BASE_URL
      ? `${import.meta.env.BASE_URL}index.html`
      : import.meta.env.BASE_URL
  ),
  routes: [
    {
      path: RouteList.LMS.INDEX.PATH,
      name: RouteList.LMS.INDEX.NAME,
      components: {},
      meta: {
        requiresAuth: true,
        requiresSubscription: true
      }
    },
    {
      path: RouteList.AUTH.LOGOUT.PATH,
      name: RouteList.AUTH.LOGOUT.NAME,
      components: {},
      meta: {
        requiresAuth: true,
        requiresSubscription: false
      }
    },
    {
      path: RouteList.MISC.PLACEHOLDER.PATH,
      name: RouteList.MISC.PLACEHOLDER.NAME,
      components: {}
    },
    {
      path: RouteList.MISC.NOT_FOUND.PATH,
      name: RouteList.MISC.NOT_FOUND.NAME,
      components: {
        default: ErrorLayout,
        NavBar: GuestNavBar
      },
      props: {
        default: {
          errorCode: 404
        }
      }
    },
    {
      path: RouteList.MISC.UNAUTHORIZED.PATH,
      name: RouteList.MISC.UNAUTHORIZED.NAME,
      components: {
        default: ErrorLayout,
        NavBar: GuestNavBar
      },
      props: {
        default: {
          errorCode: 403
        }
      }
    },
    {
      path: RouteList.MISC.ERROR.PATH,
      name: RouteList.MISC.ERROR.NAME,
      components: {
        default: ErrorLayout,
        NavBar: GuestNavBar
      }
    },
    ...Auth,
    ...Dashboard,
    ...UserSettings,
    ...IntelliForms,
    ...Intercept,
    ...BackOffice,
    ...Public,
    ...Marketplace,
    ...Website,
    ...TaskRoutes,
    ...Academy,
    ...UI
  ]
})

router.beforeEach(async (to, from) => {
  // Set page title
  setDocumentTitle(to.meta.title)

  // on view change, cancel all previous requests
  if (from.name && to.name !== from.name) {
    await store.dispatch('DashboardModule/abortRequests')
  }

  if (RouteWhiteList.includes(to.name?.toString() || '')) {
    return
  }

  const isAuthenticated = store.getters['AuthModule/isAuthenticated']
  const isAdmin: ComputedRef<boolean> = computed(() => store.getters['AuthModule/isAdmin'])
  const businessSlug: ComputedRef<string> = computed(
    () => store.getters['BusinessModule/businessSlug']
  )
  const business: ComputedRef<BusinessDto> = computed(
    () => store.getters['BusinessModule/mainBusiness']
  )
  const userAbilities: ComputedRef<PermissionDto[]> = computed(
    () => store.getters['AuthModule/abilities']
  )
  const businessUserAbilities: ComputedRef<PermissionDto[]> = computed(
    () => store.getters['BusinessModule/abilities']
  )

  const abilityNames: ComputedRef<string[]> = computed(() =>
    userAbilities.value.concat(businessUserAbilities.value).map((ua) => ua.name)
  )

  const user: ComputedRef<UserDto> = computed(() => store.getters['AuthModule/user'])

  // If route requires authentication and user is not authenticated
  if (to.meta.requiresAuth && !isAuthenticated) {
    if (to.name === RouteList.AUTH.REGISTRATION.STEPS.USER.NAME) {
      return {
        name: RouteList.AUTH.REGISTRATION.STEPS.USER.NAME
      }
    }

    return {
      name: RouteList.AUTH.LOGIN.NAME,
      query: { redirect: to.query.redirect ? to.query.redirect : to.fullPath }
    }
  }

  if (to.meta.requiresAuth && isAuthenticated) {
    await Promise.all([
      waitFor(() => store.getters.authLoaded),
      waitFor(() => store.getters.businessLoaded)
    ])
  }

  if (
    isAuthenticated &&
    !!to.params.businessSlug &&
    !!from.params.businessSlug &&
    to.params.businessSlug !== from.params.businessSlug
  ) {
    store.dispatch('BusinessModule/setIsStoreLoaded', false)

    await store.dispatch('BusinessModule/setSessionBusiness', to.params.businessSlug)
    await store.dispatch('BusinessModule/setMainBusiness', to.params.businessSlug)

    store.dispatch('BusinessModule/setIsStoreLoaded', true)
  }

  if (isAdmin.value && !businessSlug.value && to.name?.toString().includes('back-office')) {
    await store.dispatch('BusinessModule/setBusinessSlug', DummyBackOfficeSlug)
  }

  if (to.meta.requiresAuth && to.meta.requiresSubscription) {
    // if route requires authentication and requires subscription
    const business = await store.getters['BusinessModule/mainBusiness']

    if (business?.type === BusinessTypeEnum.New) {
      notifyError('Business is not fully registered. Please finish business registration')
      return {
        name: RouteList.AUTH.REGISTRATION.STEPS.USER.NAME
      }
    }

    if (business?.type === BusinessTypeEnum.Pending) {
      return {
        name: RouteList.AUTH.REGISTRATION.STEPS.PAYMENT.NAME
      }
    }
  }

  if (RouteList.AUTH.LOGOUT.NAME === to.name) {
    await store.dispatch('AuthModule/logout')
    return {
      name: RouteList.AUTH.LOGIN.NAME
    }
  }

  // Logic comes from 'resolveDynamicRoute' in useDynamicMenu to get the route for which the
  // business has the feature enabled and the user has the abilities permissions
  const resolveRoute = () => {
    const possibleRoutes = [...LMS_ROUTES, ...EMS_ROUTES, ...SETTING_ROUTES]

    const features = computed(() => business.value?.features ?? [])

    const featureNames = computed(() => features.value.map((f) => f.system_name))

    const dynamicRoute = possibleRoutes.find((route) => {
      const resolvedRoute = router.resolve({
        name: route,
        params: { businessSlug: businessSlug.value }
      })

      if (
        resolvedRoute.meta.feature &&
        !featureExistsInFeatures(resolvedRoute.meta.feature, featureNames.value)
      ) {
        return false
      }

      if (!resolvedRoute.meta.permission) {
        return route
      }

      return permissionExistsInPermissions(
        resolvedRoute.meta.permission,
        !!resolvedRoute.meta.admin,
        isAdmin.value,
        abilityNames.value
      )
    })

    if (dynamicRoute === undefined) {
      return possibleRoutes[0]
    }

    return dynamicRoute
  }

  if (
    ![
      RouteList.AUTH.BUSINESS_CHOICE.NAME,
      RouteList.AUTH.LOGIN.NAME,
      RouteList.AUTH.PASSWORD.FORGOT_PASSWORD.NAME,
      RouteList.AUTH.PASSWORD.RESET_PASSWORD.NAME,
      RouteList.AUTH.LOGOUT.NAME,
      RouteList.AUTH.INVITATIONS.USER.NAME,
      RouteList.AUTH.INVITATIONS.ADMIN.NAME,
      RouteList.AUTH.REGISTRATION.STEPS.USER.NAME
    ].includes(to.name?.toString() || '') &&
    !to.name?.toString().includes('back-office') &&
    businessSlug.value === null
  ) {
    return {
      name: RouteList.AUTH.BUSINESS_CHOICE.NAME,
      query: { redirect: to.query.redirect ? to.query.redirect : to.fullPath }
    }
  }

  if (RouteList.AUTH.LOGIN.NAME === to.name && isAuthenticated) {
    if (to.query.redirect) {
      // if there was a redirect and user was authenticated before
      // continue with redirect and preserve all query parameters
      const redirectString = to.query.redirect.toString()

      if (to.query.redirect.includes('?')) {
        return {
          path: redirectString,
          query: Object.fromEntries(new URLSearchParams(redirectString.split('?')[1]).entries())
        }
      }

      return {
        path: redirectString
      }
    }

    if (isAdmin.value) {
      return {
        name: RouteList.BACK_OFFICE.BUSINESSES.INDEX.NAME
      }
    }

    return businessSlug.value
      ? {
          name: resolveRoute(),
          params: {
            status: 'current',
            businessSlug: businessSlug.value
          }
        }
      : {
          name: RouteList.AUTH.BUSINESS_CHOICE.NAME
        }
  }

  if (RouteList.LMS.INDEX.NAME === to.name) {
    return {
      name: resolveRoute(),
      params: {
        status: LmsLeadRoutesEnum.CURRENT,
        businessSlug: businessSlug.value
      }
    }
  }

  // Check feature - false = 404
  if (to.meta.feature) {
    await waitFor(() => !!business.value)

    const featureNames = (business.value?.features ?? []).map((f) => f.system_name)

    if (!featureExistsInFeatures(to.meta.feature, featureNames)) {
      const match = to.matched.find((m) => m.children.length === 0)

      if (!match) {
        return {
          name: RouteList.MISC.NOT_FOUND.NAME
        }
      }

      match.components.default = ErrorLayout
      match.props.default = {
        errorCode: 404
      }
    }
  }

  // Check permission - false = 403
  if (to.meta.permission) {
    await waitFor(() => !!user.value)

    if (
      !permissionExistsInPermissions(
        to.meta.permission,
        !!to.meta.admin,
        isAdmin.value,
        abilityNames.value
      )
    ) {
      const match = to.matched.find((m) => m.children.length === 0)

      if (!match) {
        return {
          name: RouteList.MISC.UNAUTHORIZED.NAME
        }
      }

      match.components.default = ErrorLayout
      match.props.default = {
        errorCode: 403
      }
    }
  }
})

router.afterEach(async (to, from) => {
  if (store.getters['DashboardModule/isSubSidebarOpen']) {
    await store.dispatch('DashboardModule/hideSubSidebar')
  }

  // if route actually changed, check if app version is outdated
  if (to.name !== from.name) {
    const currentVersion = import.meta.env.VITE_BUILD_VERSION
    const newestVersion = store.getters['DashboardModule/appVersion']

    // if current app version is outdated, reload page after a redirect, to seamlessly update client app
    if (currentVersion && newestVersion && currentVersion !== newestVersion) {
      const lastRefresh = window.sessionStorage.getItem('lastAppRefresh') ?? null

      if (lastRefresh && Number(lastRefresh) + 60 * 1000 >= Date.now()) {
        // 1 minute
        // app is outdated, but enough time hasn't passed since last refresh - do nothing
      } else {
        window.sessionStorage.setItem('lastAppRefresh', Date.now().toString())
        location.reload() // reload page to fetch the newest app
      }
    }
  }
})

export default router
