<template>
  <BaseFormFieldLayout
    :id="id"
    :label="label"
    :note="note"
    :error-message="errorMessage"
    :field-class="fieldClass"
  >
    <textarea
      :id="id"
      :value="model"
      v-bind="$attrs"
      class="form-control"
      :class="{ 'is-invalid': errorMessage }"
      @input="handleInput"
    ></textarea>
  </BaseFormFieldLayout>
</template>

<script lang="ts" setup>
const model = defineModel<string>()

defineProps({
  id: {
    type: [String || Number],
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  note: {
    type: String,
    default: ''
  },
  errorMessage: {
    type: String,
    default: ''
  },
  fieldClass: {
    type: String,
    default: ''
  },
})

const emit = defineEmits<{
  (e: 'input', value: string | unknown): void
}>()

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  model.value = target.value
  emit('input', model.value)
}
</script>
