export default {
  EMS: {
    INDEX: {
      PATH: '/',
      NAME: 'index'
    },
    EMS: {
      PATH: '/b/:businessSlug/ems/',
      NAME: 'ems'
    },
    LEADS: {
      INDEX: {
        PATH: 'candidates/:status?',
        NAME: 'ems.leads.index'
      },
      LEAD: {
        PATH: 'candidates/:leadId(\\d+)',
        NAME: 'ems.leads.show'
      }
    },
    CENTER: {
      INDEX: {
        PATH: 'center',
        NAME: 'ems.center.index'
      },
      CLASSROOMS: {
        PATH: 'center/classrooms',
        NAME: 'ems.center.classrooms'
      }
    },
    STATISTICS: {
      INDEX: {
        PATH: 'statistics',
        NAME: 'ems.statistics.index'
      },
      OVERVIEW: {
        PATH: 'statistics/overview',
        NAME: 'ems.statistics.overview'
      },
      REPORTS: {
        INDEX: {
          PATH: 'statistics/reports',
          NAME: 'ems.statistics.index'
        },
        REPORT: {
          PATH: 'statistics/report/:reportId',
          NAME: 'ems.statistics.report'
        },
        EMBEDDED_REPORT: {
          PATH: 'statistics/report/embed/:reportId',
          NAME: 'ems.statistics.embed.report'
        }
      }
    },
    CONVERSATIONS: {
      INDEX: {
        PATH: 'conversations',
        NAME: 'ems.conversations.index'
      },
      CALLS: {
        PATH: 'conversations/calls',
        NAME: 'ems.conversations.calls'
      },
      SMS: {
        PATH: 'conversations/sms',
        NAME: 'ems.conversations.sms'
      },
      EMAILS: {
        PATH: 'conversations/emails',
        NAME: 'ems.conversations.emails'
      },
      MESSENGER: {
        PATH: 'conversations/messenger',
        NAME: 'ems.conversations.messenger'
      }
    },
    MARKETING: {
      INDEX: {
        PATH: 'marketing',
        NAME: 'ems.marketing.index'
      },
      WORKFLOWS: {
        INDEX: {
          PATH: 'marketing/workflows',
          NAME: 'ems.marketing.workflows'
        },
        CREATE: {
          PATH: 'marketing/workflows/create',
          NAME: 'ems.marketing.workflows.create'
        },
        WORKFLOW: {
          INDEX: {
            PATH: 'marketing/workflows/:workflowId(\\d+)',
            NAME: 'ems.marketing.workflows.show'
          },
          EDIT: {
            PATH: 'marketing/workflows/:workflowId(\\d+)/edit',
            NAME: 'ems.marketing.workflows.edit'
          }
        }
      },
      TEMPLATES: {
        INDEX: {
          PATH: 'marketing/templates',
          NAME: 'ems.marketing.templates'
        },
        SMS: {
          INDEX: {
            PATH: 'marketing/templates/sms',
            NAME: 'ems.marketing.templates.sms'
          }
        },
        EMAIL: {
          INDEX: {
            PATH: 'marketing/templates/email',
            NAME: 'ems.marketing.templates.email'
          }
        },
        TASK: {
          INDEX: {
            PATH: 'marketing/templates/task',
            NAME: 'ems.marketing.templates.task'
          }
        },
        FACEBOOK: {
          INDEX: {
            PATH: 'marketing/templates/facebook',
            NAME: 'ems.marketing.templates.facebook'
          }
        }
      },
      CAMPAIGNS: {
        INDEX: {
          PATH: 'marketing/campaigns',
          NAME: 'ems.marketing.campaigns'
        },
        SENT: {
          PATH: 'marketing/campaigns/sent',
          NAME: 'ems.marketing.campaigns.sent'
        },
        DRAFT: {
          PATH: 'marketing/campaigns/draft',
          NAME: 'ems.marketing.campaigns.draft'
        },
        SCHEDULED: {
          PATH: 'marketing/campaigns/scheduled',
          NAME: 'ems.marketing.campaigns.scheduled'
        },
        CREATE: {
          PATH: 'marketing/campaigns/create',
          NAME: 'ems.marketing.campaigns.create'
        },
        SHOW: {
          PATH: 'marketing/campaigns/:campaignId(\\d+)',
          NAME: 'ems.marketing.campaigns.show'
        },
        EDIT: {
          PATH: 'marketing/campaigns/:campaignId(\\d+)/edit',
          NAME: 'ems.marketing.campaigns.edit'
        }
      }
    },
    INTELLIFORMS: {
      INDEX: {
        PATH: 'intelliforms',
        NAME: 'ems.intelliforms.index'
      },
      PACKAGES: {
        INDEX: {
          PATH: 'intelliforms/packages',
          NAME: 'ems.intelliforms.packages'
        },
        CREATE: {
          PATH: 'intelliforms/packages/create',
          NAME: 'ems.intelliforms.packages.create'
        },
        PACKAGE: {
          INDEX: {
            PATH: 'intelliforms/packages/:packageId(\\d+)',
            NAME: 'ems.intelliforms.packages.package.show'
          },
          EDIT: {
            PATH: 'intelliforms/packages/:packageId(\\d+)/edit',
            NAME: 'ems.intelliforms.packages.package.edit'
          }
        }
      },
      DOCUMENTS: {
        INDEX: {
          PATH: 'intelliforms/documents',
          NAME: 'ems.intelliforms.documents'
        },
        CREATE: {
          PATH: 'intelliforms/documents/create',
          NAME: 'ems.intelliforms.documents.create'
        },
        DOCUMENT: {
          EDIT: {
            PATH: 'intelliforms/documents/:documentId(\\d+)/edit',
            NAME: 'ems.intelliforms.documents.document.edit'
          }
        }
      }
    },
    TOOLS: {
      INDEX: {
        PATH: 'tools',
        NAME: 'ems.tools.index'
      },
      EXPORT: {
        INDEX: {
          PATH: 'tools/export',
          NAME: 'ems.tools.export'
        },
        CREATE: {
          PATH: 'tools/export/create',
          NAME: 'ems.tools.export.create'
        }
      },
      DUPLICATES: {
        INDEX: {
          PATH: 'tools/duplicates',
          NAME: 'ems.tools.duplicates'
        },
        CUSTOM: {
          PATH: 'tools/duplicates/custom',
          NAME: 'ems.tools.duplicates/custom'
        },
        MERGE: {
          PATH: 'tools/duplicates/merge',
          NAME: 'ems.tools.duplicates/merge'
        }
      }
    }
  }
}
